<template>
  <div :class="{ 'custom-table--few-items': tableData.length <= 10 }" class="custom-table">

    <ToggleEpack v-if="epackWarning" :epack-i-d="currentEpack" :is-activate="currentActive" @disable="closeWarning"
                 @toggleEpackConfirm="toggleEpackConfirm"/>

    <div class="tables" @wheel="scrollToBottom">
      <div
        :style="{width: smallTable ? '170px' : '65%', minWidth: smallTable ? '0px' : windowLength  < 1100 ? '500px' : '820px'}"
        class="live-core-wrapper">
        <div
          class="live-core"
        >
          <table>
            <thead>
            <tr>
              <transition name="fade">
                <th
                  v-if="!smallTable"
                  :class="['sort', sort['licenseName'], 'sort-licenseName', {'parrent-retailers-exist': Object.keys(retailerGroups).length}]"
                  @click="sortDataBy('licenseName')"
                >
                  <div class="cell">
                    License Name
                    <ArrowSort/>
                  </div>
                </th>
              </transition>
              <transition name="fade">
                <th
                  v-if="!smallTable"
                  :class="['sort', sort['productName'], 'sort-productName', {'parrent-retailers-exist': Object.keys(retailerGroups).length}]"
                  @click="sortDataBy('productName')"
                >
                  <div class="cell">
                    Product Name
                    <ArrowSort/>
                  </div>
                </th>
              </transition>
              <transition name="fade">
                <th
                  v-if="!smallTable"
                  :class="['sort', sort['ean'], 'sort-ean', {'parrent-retailers-exist': Object.keys(retailerGroups).length}]"
                  @click="sortDataBy('ean')"
                >
                  <div class="cell">
                    EAN
                    <ArrowSort/>
                  </div>
                </th>
              </transition>
              <th
                :class="['sort', sort['mpn'], 'sort-mpn', {'parrent-retailers-exist': Object.keys(retailerGroups).length}]"
                @click="sortDataBy('mpn')"
              >
                <div class="cell">
                  MPN
                  <ArrowSort/>
                </div>
              </th>
              <th
                v-if="!smallTable"
                :class="['sort', sort['active'], 'sort-active', {'parrent-retailers-exist': Object.keys(retailerGroups).length}]"
                @click="sortDataBy('active')"
              >
                <div class="cell">
                  Active
                  <ArrowSort/>
                </div>
              </th>
              <th v-if="!smallTable"></th>
            </tr>
            <tr></tr>
            </thead>
            <tbody class="js-scroll-target-1" @scroll="onScroll">
            <tr
              v-for="field in tableData"
              :key="field.ean"
              :class="{notActive: !field.active}"
            >
              <transition name="fade">
                <td v-if="!smallTable">
                  <div class="cell">
                    {{ field.licenseName }}
                  </div>
                </td>
              </transition>
              <transition name="fade">
                <td v-if="!smallTable">
                  <div class="cell">
                    <div :title='field.productName' class="cell__box">
                      {{ field.productName }}
                    </div>
                  </div>
                </td>
              </transition>
              <transition name="fade">
                <td
                  v-if="!smallTable"
                >
                  <div class="cell">
                    <div :title='field.ean' class="cell__box">
                      {{ field.ean }}
                    </div>
                  </div>
                </td>
              </transition>
              <td>
                <div class="cell">
                  <div :title='field.mpn' class="cell__box">
                    {{ field.mpn }}
                  </div>
                </div>
              </td>
              <td
                v-if="!smallTable"
                class="no-wrap"
              >
                <div class="cell">
                  <vn-checkbox
                    :activated="field.active"
                    :disabled="!checkForPermission('Epackage_activation')"
                    :epackID="field.epackID"
                    @toggleEpack="toggleEpack"
                  />
                </div>
              </td>
              <td
                v-if="!smallTable"
                class="nowrap"
              >
                <div class="cell monitor-icons">
                  <Download
                    :epackID="field.epackID"
                    :onClick="downloadEpack"
                  />
                  <Eye
                    :epackID="field.epackID"
                    :openPreview="openPreview"
                  />
                  <Analytic
                    :epackID="field.epackID"
                    :openPreview="openAnalytic"
                  />
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <button :class="{active: !this.smallTable}" class="show-button" @click="changeTableShow">
          <svg fill="none" height="10" viewBox="0 0 7 10" width="7" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 9L5.5 5L1.5 0.999998" stroke="white" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2"/>
          </svg>
        </button>
      </div>
      <div
        :style="{width: smallTable ? 'calc(100% - 170px)' : '40%'}"
        class="live-retailers"
      >
        <div class="table-wrapper">
          <table :style="{'position': tableData.length <= 7 ? 'static' : 'relative'}">
            <thead class="table-wrapper-thead">
            <tr v-if="retailerGroupsSorted.length">
              <th
                v-for="([key, items], index) in retailerGroupsSorted"
                :key="index"
                :style="`width: 112px; height: 74px;`"
              >
                <div class="table-header__item"
                     :class="{'table-header__item-cursor-helper': items.length < 2 && checkParams(items[0])}">
                  {{ key }}
                  <Error v-if="checkParams(items[0]) && items[0].statisticWorks === '0'"/>
                  <div class="table-header__info"
                       :class="{'table-header__info-last': retailerGroupsSorted.length > 1 && index === retailerGroupsSorted.length - 1, 'table-header__info-one': tableData.length <= 4}"
                       v-if="items.length < 2 && checkParams(items[0])">
                    <div class="table-header__info__header">{{ key }}</div>
                    <div class="table-header__info__body">
                      <div class="table-header__info__body-label">Mapping:</div>
                      <div class="table-header__info__body-pill">
                        {{ items[0].mappingBy === null ? "Undefined" : items[0].mappingBy }}
                      </div>
                      <div class="table-header__info__body-label">Not in stock:</div>
                      <div class="table-header__info__body-pill">
                        <Check v-if="items[0].hideContent === 0"/>
                        <Question v-else-if="items[0].hideContent === 2"/>
                        <Cross v-else/>
                        <span>{{
                            items[0].hideContent === 0 ? "Rich-content on" : (items[0].hideContent === 2 ? "Variable" : "Rich-content off")
                          }}</span>
                      </div>
                      <div class="table-header__info__body-label">Statistics:
                        <Error v-if="!items[0].statisticWorks"/>
                      </div>
                      <div class="table-header__info__body-pill">
                        <Check v-if="items[0].statisticWorks"/>
                        <Cross v-else/>
                        <span>{{ items[0].statisticWorks ? "Are collected" : "Are not collected" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="items.length >= 2" class="table-header__subitems">
                  <div
                    v-for="(sub_item, sub_index) in items"
                    :key="`${index}-${sub_index}`"
                    :title="sub_item.key"
                    class="table-header__subitem"
                  >
                    <div class="table-header__subitem-title"
                         :class="{'table-header__item-cursor-helper': checkParams(sub_item)}">
                      {{ sub_item.key }}
                      <Error v-if="checkParams(sub_item) && !sub_item.statisticWorks"/>
                    </div>
                    <div class="table-header__info" v-if="checkParams(sub_item)"
                         :class="{'table-header__info-one': tableData.length <= 4}">
                      <div class="table-header__info__header">{{ sub_item.key }}</div>
                      <div class="table-header__info__body">
                        <div class="table-header__info__body-label" v-if="sub_item.mappingBy !== null">Mapping:</div>
                        <div class="table-header__info__body-pill" v-if="sub_item.mappingBy !== null">
                          {{ sub_item.mappingBy === null ? "Undefined" : sub_item.mappingBy }}
                        </div>
                        <div class="table-header__info__body-label">Not in stock:</div>
                        <div class="table-header__info__body-pill">
                          <Check v-if="sub_item.hideContent === 0"/>
                          <Question v-else-if="sub_item.hideContent === 2"/>
                          <Cross v-else/>
                          <span>{{
                              sub_item.hideContent === 0 ? "Rich-content on" : (sub_item.hideContent === 2 ? "Variable" : "Rich-content off")
                            }}</span>
                        </div>
                        <div class="table-header__info__body-label">Statistics:
                          <Error v-if="checkParams(sub_item) && !sub_item.statisticWorks"/>
                        </div>
                        <div class="table-header__info__body-pill">
                          <Check v-if="sub_item.statisticWorks"/>
                          <Cross v-else/>
                          <span>{{ sub_item.statisticWorks ? "Are collected" : "Are not collected" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            <!--              <tr>-->
            <!--                <th-->
            <!--                        v-for="label in fieldsSorted"-->
            <!--                        :key="label.title"-->
            <!--                >-->
            <!--                  {{ label.title }}-->
            <!--                </th>-->
            <!--              </tr>-->
            </thead>
            <tbody
              :class="{'overflow-auto': tableData.length > 10}"
              class="js-scroll-target-2"
            >
            <tr
              v-for="(field, fieldIndex) in tableData"
              :key="field.ean"
              :class="{notActive: !field.active}"
            >
              <td
                v-for="(label, index) in fieldsSorted"
                :key="label.title"
              >
                <div class="cell" :style="{'position': 'relative'}">
                  <BtnStatus
                    v-if="field[label.title]"
                    :alwaysToTop="tableData.length <= 4"
                    :field="field"
                    :labelIndex="index"
                    :labelsLength="fieldsSorted.length"
                    :retailer="field[label.title]"
                    :is-admin="isAdmin"
                    :retailerName="label.name"
                    :retailersInfo="retailersInfo"
                    :rowIndex="tableData.length - fieldIndex"
                    :updateKey="`${fieldIndex}-${index}`"
                    @updateActiveElement="updateActiveElement"
                  />
                  <PropertyList
                    v-if="isOpenSearch && activeRetailer && updateKey === `${fieldIndex}-${index}`"
                    ref="propertyList"
                    :className="searchClass"
                    :list-of-property="collectListOfProperties"
                  >
                    <template v-slot:Search>
                      <SearchBy
                        ref="searchBy"
                        :retailer="activeRetailer"
                        :retailerSearch="retailerSearch"
                        @open-link-modal="onOpenLinkModal"
                      />
                    </template>
                    <template v-slot:Status>
                      <Status
                        :retailer="activeRetailer"
                        :retailerSearch="retailerSearch"
                        @changeCheck="changeCheck"
                        @update="changeStock"
                        @changeStatusLink="changeStatusLink"
                      />
                    </template>
                    <template v-slot:Mapping>
                      <Mapping
                        v-if="checkForPermission('Mapping')"
                        :retailer="activeRetailer"
                        :retailerName="label.name"
                        :template-selected="activeRetailer.minisite_template_replace_direction"
                        :value="activeRetailer.sku"
                        @save="updateSKU"
                      >
                      </Mapping>
                    </template>
                    <template v-slot:Alias>
                      <Alias
                        v-if="checkForPermission('Update_license_alias')"
                        :retailer="activeRetailer"
                        :retailerName="label.name"
                        :template-selected="activeRetailer.minisite_template_replace_direction"
                        :value="activeRetailer.sku"
                        :aliases="aliases"
                        :alias-options="aliasOptions"
                        @save="updateSKU"
                      />
                    </template>
                  </PropertyList>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <va-progress-bar v-if="loading"
                       :style="{width: '100%', position: 'absolute', bottom: 0, left: 0, backgroundColor: '#ffffff'}"
                       indeterminate
      ></va-progress-bar>
    </div>
    <Paginate
      v-if="!loadOnScroll"
      v-model="currentPage"
      :clickHandler="changePage"
      :container-class="'pagination'"
      :page-count="pageCount"
    />

    <Portal to="body">
      <the-modal @close="onCloseLinkModal" v-if="linkModalData">
        <div class="the-modal__title">{{ linkModalNew ? 'Add link' : 'Edit link' }}</div>
        <input type="text" class="the-modal__input" v-model="linkModalLink">
        <div class="the-modal__buttons">
          <button class="the-modal__button the-modal__button--black" :disabled="linkModalLoading"
                  @click="onSaveLinkModal">Save
          </button>
          <button class="the-modal__button" :disabled="linkModalLoading" @click="onCloseLinkModal">Cancel</button>
        </div>
      </the-modal>
    </Portal>

    <Portal to="body">
      <the-modal @close="onCloseWarningModal" v-if="linkWarningModal">
        <div class="the-modal__title">Warning!</div>
        <div class="the-modal__text">The link must be in the format "https://www.domain_name.com/info_product/"</div>
        <div class="the-modal__buttons">
          <button class="the-modal__button the-modal__button--black" @click="onCloseWarningModal">Ok</button>
        </div>
      </the-modal>
    </Portal>
  </div>
</template>

<script>
import API from "../../services/API/API";
import {showToastError, showToastStatus, showToastSuccess} from "@/services/Helpers/HelperToast";
import {downloadFile} from "@/services/Helpers/HelperActions";
import ArrowSort from "../ui/icons/ArrowSort";
import BtnStatus from "../statistics/BtnStatus";
import Eye from "../ui/icons/Eye";
import smoothscroll from "smoothscroll-polyfill";
import Download from "../ui/icons/Download";
import SearchBy from "../statistics/SearchBy";
import {hasClassOrParent} from "@/services/Helpers/HelperDOM";
import Analytic from "../../components/ui/icons/Analytic";
import PropertyList from "@/components/statistics/PropertyList";
import Mapping from "@/components/statistics/Mapping";
import Alias from "@/components/statistics/Alias";
import Status from "@/components/statistics/Status";
import VnCheckbox from "@/components/ui/checkbox/vn-checkbox";
import ToggleEpack from "@/components/tables/pop-ups/ToggleEpack";
import {mapState} from "vuex";
import {Portal} from "portal-vue";
import TheModal from "@/components/TheModal";
import Check from "@/components/ui/icons/Check";
import Cross from "@/components/ui/icons/Cross";
import Error from "@/components/ui/icons/IconError";
import Question from "@/components/ui/icons/Question";

export default {
  name: "CustomTable",
  components: {
    Question,
    ToggleEpack,
    VnCheckbox,
    Mapping,
    Alias,
    PropertyList,
    Analytic,
    BtnStatus,
    ArrowSort,
    Eye,
    Download,
    SearchBy,
    Status,
    Portal,
    TheModal,
    Check,
    Cross,
    Error,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    retailerGroups: {
      type: Object,
      default: () => {
      },
    },
    data: {
      type: Array,
      default: () => [],
    },
    skuTable: {
      type: Object,
      default: () => {

      }
    },
    fields: {
      type: Array,
      default: () => [],
    },
    perPage: {
      type: Number,
      default: 0,
    },
    openPreview: {
      type: Function,
      default: () => {
      },
    },
    openAnalytic: {
      type: Function,
      default: () => {
      },
    },
    retailersInfo: {
      type: Array,
      default: () => [],
    },
    changeStock: {
      type: Function,
      default: () => {
      },
    },
    changeCheck: {
      type: Function,
      default: () => {
      },
    },
    changeStatusLink: {
      type: Function,
      default: () => {
      },
    },
    loadOnScroll: {
      type: Boolean,
      default: true,
    },
    updateSKU: {
      type: Function,
      default: () => {

      },
    },
    aliases: {
      type: Array,
      default: () => []
    },
    aliasOptions: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState(['currentUserProfilePermissions']),

    retailerGroupsSorted() {
      const retailers = Array.from(Object.entries(this.retailerGroups)).sort((a, b) => ('' + a[0]).localeCompare(b[0])).map(entry => {
        entry[1] = entry[1].sort((a, b) => a.key.localeCompare(b.key))
        return entry;
      })
      return retailers
    },
    fieldsSorted() {
      const retailerGroupsForCheck = [];
      Object.values(this.retailerGroups).forEach(rg => {
        retailerGroupsForCheck.push(...rg.map(rgv => this.clearRetailerLabel(rgv.key)))
      });
      return [...this.fields].filter((f) => retailerGroupsForCheck.includes(f.name)).sort((a, b) => ('' + a.title).localeCompare(b.title))
    },
    collectListOfProperties() {
      const list = ['Search', 'Status']
      if (this.checkForPermission('Mapping'))
        list.push('Mapping')
      if (this.checkForPermission('Update_license_alias'))
        list.push('Alias')

      return list;
    }
  },

  data() {
    return {
      linkModalLink: '',
      linkModalNew: false,
      linkModalLoading: false,
      linkModalData: null,
      linkWarningModal: false,
      updateKey: null,
      isOpenSearch: false,
      retailerSearch: "",
      searchClass: "",
      activeStock: null,
      activeRetailer: null,
      windowTop: 0,
      currentPage: 1,
      pageCount: 0,
      lastPage: 1,
      tableData: [],
      labels: [],
      appLayout: null,
      tables: null,
      tableRow: null,
      smallTable: false,
      scrollPosition: 0,
      activeElement: null,
      offsetTopSearchBy: "0px",
      offsetLeftSearchBy: "0px",
      sort: {
        productName: "asc",
        licenseName: "asc",
        ean: "asc",
        mpn: "asc",
        active: "asc",
      },
      loading: false,
      epackWarning: false,
      currentActive: false,
      currentEpack: "",
      windowLength: 2000,

    };
  },

  created() {
    this.windowLength = document.body.clientWidth
    this.updateDate(true);
  },

  mounted() {
    smoothscroll.polyfill();
    this.appLayout = document.querySelector(".app-layout__main-layout");
    this.tables = document.querySelector(".tables");
    this.tableRow = document.querySelector(".table-row");
    this.tableRetailers = document.querySelector(".live-retailers");
    // this.scrollHead();
    // this.appLayout.addEventListener("scroll", this.scrollHead.bind(this));
    //if (this.tableRetailers !== null)
    //this.tableRetailers.addEventListener("mousewheel", this.updateSplashPosition.bind(this));
    if (window !== null) {
      //window.addEventListener("resize", this.updateSplashPosition.bind(this));
      window.addEventListener("click", this.checkClickedSplash.bind(this));
    }
    this.synchronizeScroll()
  },

  beforeDestroy() {
    try {
      document.querySelector('.js-scroll-target-1').removeEventListener('scroll', () => {
      })
    } catch (e) {
    }
    try {
      document.querySelector('.js-scroll-target-2').removeEventListener('scroll', () => {
      })
    } catch (e) {
    }
  },

  destroyed() {
    this.appLayout.removeEventListener("scroll", this.scrollHead.bind(this));
    if (this.tableRetailers !== null)
      this.tableRetailers.removeEventListener("mousewheel", this.updateSplashPosition.bind(this));
    if (window !== null) {
      window.removeEventListener("resize", this.updateSplashPosition.bind(this));
      window.removeEventListener("click", this.checkClickedSplash.bind(this));
    }

  },

  watch: {
    perPage() {
      this.currentPage = 1;
      this.updateDate();
    },

    data() {
      this.scrollPosition = document.querySelector('.js-scroll-target-2').scrollTop;
      this.lastPage = this.currentPage;
      this.updateDate();
      this.loadNewPage(this.lastPage)
      this.scrollBack();
    },
  },

  methods: {
    onCloseWarningModal() {
      this.linkWarningModal = false;
    },

    onOpenLinkModal(retailer) {
      this.linkModalData = retailer
      this.linkModalLink = retailer?.link || ''
      this.linkModalNew = this.linkModalLink === ''
    },

    onCloseLinkModal() {
      if (!this.linkModalLoading) {
        this.linkModalData = null
        this.linkModalLink = ''
      }
    },
    clearRetailerLabel(retailer) {
      const splitRetailer = retailer.split(".");
      retailer = splitRetailer[0];
      if (splitRetailer[1]) {
        retailer += splitRetailer[1];
      }
      return retailer.toLowerCase();
    },

    async onSaveLinkModal() {
      if (!this.linkModalLoading) {
        this.linkModalLoading = true
        API.APIPut(
          `${process.env.VUE_APP_API_URL}/epackage/${this.linkModalData?.epackID}/${this.linkModalData?.id}/referrer`, {
            referrer: this.linkModalLink
          }, () => {
            this.linkModalLoading = false;
            showToastSuccess(
              "Link Saved",
              this.$toast
            );
            this.$emit('changeLink', this.linkModalData?.name, this.linkModalData?.epackID, this.linkModalLink)
            this.onCloseLinkModal()
          }, () => {
            this.linkModalLoading = false;
            this.linkWarningModal = true;
            showToastError(
              "Something wrong. <br/> Please try later",
              this.$toast
            );
          })
      }
    },

    synchronizeScroll() {
      const target_1 = document.querySelector('.js-scroll-target-1')
      const target_2 = document.querySelector('.js-scroll-target-2')

      let ignoreScrollEvents = false

      function syncScroll(element1, element2) {
        if (element1 !== null && element2 !== null) {
          element1.addEventListener('scroll', function () {
            let ignore = ignoreScrollEvents;
            ignoreScrollEvents = false;
            if (ignore) return;

            ignoreScrollEvents = true;
            element2.scrollTop = element1.scrollTop
          })
        }
      }

      syncScroll(target_1, target_2);
      syncScroll(target_2, target_1);
    },
    checkClickedSplash({target}) {
      let isBtnStatus = hasClassOrParent(target, "btn-status");
      let isSearchBy = hasClassOrParent(target, "property-list");

      if (!isSearchBy && !isBtnStatus) {
        this.resetActiveElement();
      }
    },

    updateActiveElement(data) {
      if (typeof data !== "boolean" && data.element !== this.activeElement) {
        let {element, retailerName, retailer, retailerSearch, searchClass, updateKey} = data;
        this.activeElement = element;
        this.activeRetailer = retailer;
        if (!!retailerName && !!retailer) {
          if (!!this.skuTable?.[`${retailer?.licenseName.toLowerCase()}`]?.[`${retailerName.toLowerCase()}`]) {
            this.activeRetailer.sku = Object.values(this.skuTable[`${retailer.licenseName.toLowerCase()}`][`${retailerName.toLowerCase()}`]).filter(elem => elem.ean === retailer.ean)[`0`]?.sku || ''
          }
        } else {
          this.activeRetailer.sku = '';
        }
        this.searchClass = searchClass;
        if (!!retailerSearch) {
          this.retailerSearch = retailerSearch;
        } else {
          this.retailerSearch = ''
        }
        this.isOpenSearch = true;
        this.updateKey = updateKey;
        this.updateSplashPosition();
      } else {
        this.resetActiveElement();
      }
    },

    resetActiveElement() {
      this.activeElement = null;
      this.activeStock = null;
      this.activeRetailer = null;
      this.retailerSearch = "";
      this.searchClass = "";
      this.isOpenSearch = false;
    },

    updateSplashPosition() {
      if (!this.activeElement) {
        return false;
      }

      let {offsetLeft, offsetTop} = this.activeElement;

      this.offsetTopSearchBy = offsetTop + "px";
      this.offsetLeftSearchBy = this.activeElement.getBoundingClientRect().x - this.tableRetailers.getBoundingClientRect().x + "px";
    },
    /**
     * @param {string} status
     */
    changeTableShow() {
      this.smallTable = !this.smallTable;
      setTimeout(() => {
        this.resetSort();
        this.scrollHead();
      }, 10);
    },

    /**
     * Get Epackage download link and download epack
     * @param {string} epackID
     */

    downloadEpack(epackID) {
      let statusToast = this.$toast;
      showToastStatus("Downloading...", statusToast);
      API.APIGet(
        `${process.env.VUE_APP_API_URL}/epackage/${epackID}/download-link`,
        {},
        (link) => {
          downloadFile(link);
          statusToast.clear();
          showToastSuccess("Downloaded", this.$toast);
        },
        () => {
          showToastError("Something wrong. <br/> Please try later", this.$toast);
        },
      );
    },

    scrollBack() {
      setTimeout(() => {
        const scroller = document.querySelector('.js-scroll-target-2')
        scroller.scrollTo({
          top: this.scrollPosition,
          behavior: "instant",
        })
      }, 1030)

    },

    /**
     * Control scroll of thead
     * @param {object} event
     * @returns {boolean}
     */

    scrollHead(event = false) {
      let appLayout = document.querySelector(".app-layout__main-layout");
      let windowTop = event?.currentTarget?.scrollTop || appLayout?.scrollTop;
      let tables = document.querySelector(".tables");
      let tableRow = document.querySelector(".table-row");

      if (!tables || !windowTop) {
        return false;
      }

      let th = tables.querySelectorAll("th");
      let minus = tableRow.offsetTop + tables.offsetTop + 15;
      let positionY = windowTop - minus;
      this.windowTop = positionY;

      // if (positionY >= 0) {
      //   th.forEach((elm) => {
      //     elm.style.top = `${positionY}px`;
      //     elm.classList.add("active");
      //   });
      // } else {
      //   th.forEach((elm) => {
      //     elm.style.top = 0;
      //     elm.classList.remove("active");
      //   });
      // }
    },

    scrollToTopTable() {
      if (this.windowTop > 0) {
        this.appLayout.scrollTo({
          behavior: "smooth",
          top: this.tableRow.offsetTop,
        });
      }
    },

    scrollToTopTBody() {
      const el = document.querySelector("tbody");
      if (el !== null)
        el.scrollTo(0, 0);
    },

    scrollToBottom(e) {
      const scroller = document.querySelector('.js-scroll-target-2')
      const main = document.querySelector('.app-layout__main-layout');

      if (
        main.scrollTop + main.offsetHeight + 5 < main.scrollHeight &&
        scroller.scrollTop !== 0
      ) {
        e.preventDefault()
        main.scrollTo({
          top: main.scrollHeight,
          behavior: "smooth",
        });
      }
    },

    changePage(currentPage) {
      this.currentPage = currentPage;
      this.updateDate(false);
      this.scrollToTopTBody();
    },

    updateDate(filterChange = true, useDefaultSort = false, paginated = true) {
      if (filterChange) {
        this.currentPage = 1;
        this.scrollToTopTBody();
      }

      let startIndex = (this.currentPage - 1) * this.perPage;

      if (this.currentPage === 0)
        startIndex = this.perPage

      let endIndex = startIndex + this.perPage - 1;

      if (!paginated) {
        startIndex = 0;
      }
      this.tableData = this.data.filter((info, index) => index >= startIndex && index <= endIndex);
      this.pageCount = Math.ceil(this.data.length / this.perPage);
      this.resetSort();

      if (useDefaultSort) {
        this.tableData = this.sortAsc(this.tableData, "productName");
      }
    },

    sortDataBy(type, sortType = null) {
      this.resetSort(type);
      let sort = sortType || this.sort[type].includes("asc") ? "desc" : "asc";
      if (this.windowTop >= 0) {
        sort += " active";
      }
      let sortClone = this.sort;
      sortClone[type] = sort;
      this.sort = sortClone;

      if (sort === "asc") {
        this.tableData = this.sortAsc(this.tableData, type);
      } else {
        this.tableData = this.sortDesc(this.tableData, type);
      }
      this.scrollToTopTable();
      this.scrollHead();
    },

    resetSort(type = null) {
      let sortClone = this.sort;
      Object.keys(sortClone).forEach((field) => {
        if (field !== type) {
          sortClone[field] = "asc";
          if (this.windowTop >= 0) {
            sortClone[field] += " active";
          }
        }
      });
      this.sort = sortClone;
      this.scrollHead();
    },

    sortAsc(items, field) {
      return items.slice().sort((a, b) => {
        return String(a[field]).localeCompare(String(b[field]));
      });
    },

    sortDesc(items, field) {
      return items.slice().sort((a, b) => {
        return String(b[field]).localeCompare(String(a[field]));
      });
    },

    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (this.loadOnScroll && scrollTop + clientHeight + 74 >= scrollHeight) {
        if (this.currentPage < this.pageCount) {
          this.loadNewPage(this.currentPage + 1);
        }
      }
    },

    loadNewPage(currentPage) {
      this.loading = true
      setTimeout(() => {
        this.$nextTick(() => {
          this.currentPage = currentPage;
          this.updateDate(false, false, false);
          this.$nextTick(() => {
            this.loading = false
          })
        })
      }, 1000)

    },

    toggleEpack(active, epackID) {
      this.currentActive = active
      this.currentEpack = epackID
      this.epackWarning = true;
    },

    closeWarning() {
      this.epackWarning = false
    },

    toggleEpackConfirm(active, epackID) {
      let type = !active ? 'activate' : 'deactivate';
      this.epackWarning = false;

      API.APIPut(`${process.env.VUE_APP_API_URL}/epackage/${epackID}/${type}`, {}, response => {
          this.tableData.forEach(field => {
            if (field.epackID === epackID) {
              field.active = !field.active
            }
          })
          showToastSuccess(
            `Epack was ${!active ? "activated" : "deactivated"}`,
            this.$toast
          )

        },
        () => {
          showToastError(
            "Something wrong. <br/> Please try later",
            this.$toast
          );
          this.loading = false;
        })
    },

    checkForPermission(permission) {
      return !!this.currentUserProfilePermissions?.find(perm => perm.name === permission)
    },

    checkParams(item) {
      return !!item?.mappingBy || !!item?.hideContent || !!item?.statisticWorks;
    }
  },


};
</script>

<style lang="scss">
@import 'src/sass/variables';

.custom-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  // .js-scroll-target-2 {
  //   height: calc(90vh - 220px);
  //   overflow: auto;
  // }

  .parrent-retailers-exist {
    height: 74px;
    vertical-align: middle;
  }

  tbody {
    display: block;
    max-height: calc(100vh - 74px - 24px - 12px - 72px - 20px - 5px - 15px);
    overflow: auto;
  }

  .overflow-auto {
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .tables {
    width: 100%;
    background-color: #ffffff;
    border-top: 1px solid #d6dee2;
    display: flex;
    //overflow-x: hidden;
    //overflow-y: visible;
  }

  .live-retailers {
    width: 40%;
    transition: 0.3s;
    position: relative;

    .table-wrapper {
      width: 100%;
      overflow-x: auto;
      overflow-y: visible;

      .table-header__item {
        position: relative;
        padding: 0 10px;
        height: 27px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        cursor: default;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 16px;
        &-cursor-helper {
          cursor: help;
        }
      }

      .table-header__info {
        display: none;
        position: absolute;
        width: 166px;
        padding: 16px;
        z-index: 100;
        text-align: left;
        left: 0;
        top: calc(100% + 5px);
        transform: translateY(-4px);
        background-color: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        text-transform: none;

        &-one {
          top: initial;
          bottom: calc(100% + 5px);
        }

        &-last {
          left: unset;
          right: 0;
        }

        &__header {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          text-transform: uppercase;
          color: black;
          margin-bottom: 16px;
        }

        &__body {
          &-label {
            margin-bottom: 4px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #8d9ca8;
            display: flex;
          }

          &-pill {
            padding: 3px 8px;
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            background: #ffffff;
            border: 1px solid #d6dee2;
            border-radius: 24px;
            width: fit-content;

            svg {
              width: auto;
              margin-right: 6px;
            }

            &:not(:last-child) {
              margin-bottom: 16px;
            }
          }
        }
      }

      .table-header__subitems {
        display: flex;
      }
    }

    .table-header__subitem {
      cursor: default;
      width: 112px;
      padding: 0 10px;
      position: relative;

      &-title {
        @include clamped-text(1);
      }
    }

    .table-header__item,
    .table-header__subitem {
      &:hover {
        .table-header__info {
          display: block;
        }
      }
    }

    thead {
      display: block;
    }

    table tbody tr {
      width: unset;
    }

    td,
    th {
      width: 112px;
      min-width: 112px;
    }
  }

  .live-core {
    width: 100%;
    overflow-y: hidden;
    box-shadow: 2px 0 6px rgba(28, 46, 55, 0.08);
    transition: 0.3s;
    z-index: 40;
    background-color: #ffffff;
    position: relative;

    ::-webkit-scrollbar {
      width: 10px;
    }

    .sort-licenseName,
    tbody tr td:nth-child(1) {
      width: 110px;
    }

    .sort-productName,
    tbody tr td:nth-child(2) {
      width: 180px;
    }

    .sort-ean,
    tbody tr td:nth-child(3) {
      width: 150px;
    }

    .sort-mpn,
    tbody tr td:nth-child(4) {
      width: 150px;
    }

    .sort-active,
    tbody tr td:nth-child(5) {
      width: 90px;
    }

    th,
    tbody tr td:nth-child(6) {
      width: 140px;
    }
  }

  .sq-black {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: black;
    z-index: 10;
  }

  table {
    min-width: 100%;
    min-height: 100%;
    border-collapse: separate;
    position: relative;

    thead {
      th {
        .cell {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          border-bottom: 1px solid #d6dee2;
          padding-top: 10px;
          padding-bottom: 10px;
          background-color: #ffffff;
          z-index: 60;
          margin: 0.625rem;
          height: 52px;
          position: relative;

          svg {
            stroke: #8d9ca8;
            transition: 0.26s stroke;
            cursor: pointer;
            margin-left: 4px;
          }

          &.sort {
            cursor: pointer;
          }

          &.desc {
            svg {
              transform: rotate(180deg);
            }
          }

          &.active {
            position: sticky;
          }

          &:hover {
            svg {
              stroke: #000000;
            }
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #d6dee2;
      }

      td {
        padding: 0;
        vertical-align: middle;
        border-bottom: 1px solid #d6dee2;

        .cell {
          margin: 0.625rem;
          height: 52px;
          position: relative;

          &__box {
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        svg {
          fill: #8d9ca8;
          transition: 0.26s;
          cursor: pointer;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            fill: #000000;
          }
        }

        &.nowrap {
          white-space: nowrap;
        }
      }
    }
  }

  .pagination {
    display: inline-flex;
    border: 1px solid #d6dee2;
    border-radius: 6px;
    margin-top: 24px;

    li {
      font-size: 14px;
      line-height: 18px;
      padding: 4px;

      a {
        display: block;
        padding: 7px 0;
        border-radius: 6px;
        background-color: #ecedee;
        transition: 0.26s;
        width: 32px;
        height: 32px;
        text-align: center;
      }

      &:first-child,
      &:last-child {
        a {
          padding: 7px 16px;
          background-color: transparent;
          height: 32px;
          width: auto;
        }
      }

      &:hover {
        a {
          background-color: #d6dee2;
        }
      }

      &.active {
        a {
          background-color: #000000;
          cursor: default;
          color: #ffffff;
        }
      }

      &.disabled {
        opacity: 0.5;

        a {
          cursor: default;
          background-color: transparent;
        }
      }
    }
  }

  .live-core-wrapper {
    position: relative;
  }

  .show-button {
    z-index: 41;
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 40px;
    background: #d6dee2;
    border: 1px solid #d6dee2;
    border-radius: 6px;
    padding: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #c4d0da;
    }

    svg {
      width: 4px;
      height: 8px;
    }

    &.active {
      transform: rotate(180deg) translate(50%, 50%);
    }
  }

  &--few-items {
    tbody {
      overflow: unset;
    }

    .live-retailers {
      .table-wrapper {
        padding-top: 500px;
        margin-top: -500px;
      }
    }
  }
}

.monitor-icons {
  display: flex;
}

.notActive {
  display: none !important;
  background-color: #e5e8ea;
}

@media (max-width: 768px) {
  .custom-table {
    tbody {
      max-height: calc(100vh - 74px - 24px - 12px - 117px - 20px - 5px);
    }
  }
}
</style>
