<template>
  <svg @click="onClick(epackID)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 11L12 16L8 11H11V4H13V11H16ZM4 18H20V11H22V18C22 19.103 21.103 20 20 20H4C2.897 20 2 19.103 2 18V11H4V18Z"></path>
  </svg>
</template>

<script>
  export default {
    name: "Download",
    props: {
      onClick: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
