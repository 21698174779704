<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33333 11.3333C5.33333 11.6827 5.65065 12 6 12C6.34935 12 6.66667 11.6827 6.66667 11.3333V6.66667H11.3333C11.6827 6.66667 12 6.34935 12 6C12 5.65065 11.6827 5.33333 11.3333 5.33333H6.66667V0.666667C6.66667 0.317314 6.34935 -4.25472e-07 6 0C5.65065 -4.27659e-07 5.33333 0.317314 5.33333 0.666667V5.33333H0.666667C0.317314 5.33333 -2.65511e-07 5.65065 0 6C-1.87716e-07 6.34935 0.317314 6.66667 0.666667 6.66667H5.33333V11.3333Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: "Plus",
};
</script>
