var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"status"},[_c('div',{staticClass:"status__content"},[_c('div',{staticClass:"status__wrapper"},[((_vm.retailer.status === 'no-visits' ||
      _vm.retailer.status === 'rarely' || _vm.retailer.status === 'warning' || _vm.retailer.status === 'success' || _vm.retailer.noLink) && _vm.retailer.stock === 'in-stock'||
       _vm.retailer.stock === 'data-exist')?_c('div',{staticClass:"status__wrapper ml-4"},[_c('div',[_c('h2',{staticClass:"mt-3"},[_vm._v("Reason")]),_c('div',{staticClass:"status__box"},[_c('div',{staticClass:"checkboxes"},[_c('va-radio-button',{attrs:{"option":2,"value":_vm.reason,"label":"No script"},on:{"input":function($event){_vm.reason = 2}}}),_c('va-radio-button',{attrs:{"option":4,"value":_vm.reason,"label":"No brand"},on:{"input":function($event){_vm.reason = 4}}}),_c('va-radio-button',{attrs:{"option":7,"value":_vm.reason,"label":"No ean"},on:{"input":function($event){_vm.reason = 7}}}),_c('va-radio-button',{attrs:{"option":1,"value":_vm.reason,"label":"Incorrect ean"},on:{"input":function($event){_vm.reason = 1}}}),_c('va-radio-button',{attrs:{"option":3,"value":_vm.reason,"label":"Incorrect script"},on:{"input":function($event){_vm.reason = 3}}}),_c('va-radio-button',{attrs:{"option":5,"value":_vm.reason,"label":"Incorrect brand"},on:{"input":function($event){_vm.reason = 5}}}),_c('va-radio-button',{attrs:{"option":6,"value":_vm.reason,"label":"Incorrect domain"},on:{"input":function($event){_vm.reason = 6}}})],1)]),_c('va-input',{staticClass:"input--border",attrs:{"placeholder":_vm.$t('Reason link')},model:{value:(_vm.reasonLink),callback:function ($$v) {_vm.reasonLink=$$v},expression:"reasonLink"}})],1),_c('va-button',{on:{"click":_vm.onReasonSend}},[_vm._v("SEND")])],1):_vm._e(),(_vm.retailer.status !== 'no-map')?_c('div',{staticClass:"status__box"},[_c('div',{staticClass:"status__box__title__sub"},[_vm._v("Checked")]),_c('div',{staticClass:"checkboxes"},[_c('va-button-group',{staticClass:"button__group"},[_c('va-button',{staticClass:"button__group__elem",class:{
              button__group__black: _vm.checked,
              button__group__white: !_vm.checked
            },on:{"click":function($event){return _vm.updateCheck(true)}}},[_vm._v("YES ")]),_c('va-button',{staticClass:"button__group__elem",class:{
              button__group__black: !_vm.checked,
              button__group__white: _vm.checked
            },on:{"click":function($event){return _vm.updateCheck(false)}}},[_vm._v("NO ")])],1)],1)]):_vm._e(),_c('div',{staticClass:"status__box"},[_c('div',{staticClass:"status__box__title__sub"},[_vm._v("Availability in stock")]),_c('div',{staticClass:"checkboxes"},[_c('va-button-group',{staticClass:"button__group"},[_c('va-button',{staticClass:"button__group__elem",class:{
              button__group__black: _vm.inStock,
              button__group__white: !_vm.inStock
            },on:{"click":function($event){return _vm.updateStock(true)}}},[_vm._v("YES ")]),_c('va-button',{staticClass:"button__group__elem",class:{
              button__group__black: !_vm.inStock,
              button__group__white: _vm.inStock
            },on:{"click":function($event){return _vm.updateStock(false)}}},[_vm._v("NO ")])],1)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }