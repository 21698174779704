<template>
  <div
    :class="btnStatusClass"
    @click="openSplash">
    <div class="btn-status__box">
      <span class="status-label status-label__white" v-if="retailer.status === 'success' && retailer.noLink" >{{ retailer.text }}</span>
      <span class="status-label">{{ retailer.noLink ? 'No link' : retailer.text }}</span>
      <span v-if="retailer.status !== 'success' && retailer.status !== 'no-map' || retailer.noLink && retailer.status !== 'success'"
            :class="{'in-stock': retailer.stock === 'in-stock'}"
            class="stock">{{
          retailer.stock === "in-stock" ? "In stock" : "Not in stock"
        }}</span>
      <IconError v-if="!retailer.correct && isAdmin" class="btn-status__box-error"/>
      <IconChecked v-if="retailer.check && retailer.status !== 'no-map'" :class="['check-status']"/>
<!--      <IconNotChecked v-if="!retailer.check && retailer.status !== 'no-map'" :class="['check-status']"/>-->
    </div>
  </div>
</template>

<script>
import IconChecked from "../../components/ui/icons/IconChecked";
import IconError from "@/components/ui/icons/IconError.vue";
// import IconNotChecked from "../../components/ui/icons/IconNotChecked";
import {mapState} from "vuex";

export default {
  name: "BtnStatus",
  components: {
    IconChecked,
    // IconNotChecked,
    IconError,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    retailer: {
      type: Object,
      default: () => {
      },
    },
    field: {
      type: Object,
      default: () => {
      },
    },
    labelsLength: {
      type: Number,
      default: 0,
    },
    labelIndex: {
      type: Number,
      default: 0,
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    retailersInfo: {
      type: Array,
      default: () => [],
    },
    retailerName: {
      type: String,
      default: '',
    },
    updateKey: {
      required: true,
      type: String
    },
    alwaysToTop: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      isOpenSearch: false,
      searchClass: "",
      retailerSearch: "",
      stock: this.retailer.stock,
    };
  },

  computed: {
    ...mapState({
      retailerReasonData: state => state.retailerReasonData
    }),
    btnStatusClass() {
      if (this.isAdmin) {
        return ['btn-status', 'modal', this.retailer.status, {'no-link': this.retailer.noLink}, !(this.retailer.link && this.retailer.humanDate[0] && this.retailer.humanDate[1]) && this.retailer.status !== 'no-visits' ? 'no-hover' : '', this.retailer.stock, this.isOpenSearch ? 'opened' : '']
      } else {
        return ['btn-status', this.retailer.status, {'no-link': this.retailer.noLink}, !(this.retailer.link && this.retailer.humanDate[0] && this.retailer.humanDate[1]) && this.retailer.status !== 'no-visits' ? 'no-hover' : '', this.retailer.stock, this.isOpenSearch ? 'opened' : '']
      }
    }
  },

  created() {
    let {searchUrl} = this.retailersInfo?.find(info => info.name.toLowerCase().replaceAll('.', '').replaceAll(' ', '') === this.retailer.name.replaceAll(' ', '').replaceAll('.', '')) || "";
    this.retailerSearch = searchUrl;
    this.checkIndex();
  },

  watch: {
    labelIndex() {
      this.checkIndex();
    },

    labelsLength() {
      this.checkIndex();
    },

    rowIndex() {
      this.checkIndex();
    },
  },

  methods: {
    openSplash({currentTarget}) {
      if (this.retailer.status === "no-map") {
        this.$emit('updateActiveElement', false);

      } else if (currentTarget) {
        this.$emit('updateActiveElement', {
          element: currentTarget,
          retailer: this.retailer,
          retailerName: this.retailerName,
          retailerSearch: this.retailerSearch,
          searchClass: this.searchClass,
          updateKey: this.updateKey
        });
      }
    },

    checkIndex() {
      let className = "";

      if (this.labelIndex === 0) {
        className = "--left";
      } else if (this.labelIndex === this.labelsLength - 1) {
        className = "--right";
      }

      if (this.rowIndex <= 5 || this.alwaysToTop) {
        className += " --bottom";
      }

      this.searchClass = className;
    },
  },
};
</script>

<style lang="scss">
.btn-status {
  position: relative;
  width: 88px;
  height: 52px;
  background-color: transparent;
  cursor: pointer;

  &__box {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    text-align: center;
    background-color: black;

    &-error {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      transform: translate(-40%, -40%);
      background-color: #ffffff;
      border-radius: 50%;
    }
  }

  .check-status {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    transform: translate(45%, -45%);
  }

  span,
  .tip div {
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    transition: 0.26s;
  }

  .stock {
    font-size: 12px;
    line-height: 16px;
  }

  .tip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    transition: 0.16s;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    padding: 7px 8px;
    box-sizing: border-box;

    i {
      color: #ffffff;
      position: absolute;
      top: 12px;
      right: 8px;
    }

    svg {
      width: 8px;
      height: 8px;
      position: absolute;
      top: 12px;
      right: 8px;
    }
  }

  a {
    display: block;
  }

  &.no-hover {
    cursor: default;
  }

  &:not(.no-map):not(.no-hover):hover .btn-status__box {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-4px);
  }

  &.no-visits .btn-status__box {
    background-color: #d6dee2;

    span {
      color: #60707d;
    }
  }

  &.no-map .btn-status__box {
    background-color: #a50034;
    color: #ffffff;
    cursor: default;
  }

  &.warning .btn-status__box {
    //background-color: rgb(243, 151, 43);
    background-color: #45db54;
    &:hover {
      //box-shadow: 0 4px 8px rgba(243, 151, 43, 0.3);
      box-shadow: 0 4px 8px rgba(69, 219, 84, 0.2);
    }
  }
  &.warning.modal .btn-status__box {
    background-color: rgb(243, 151, 43);
    &:hover {
      box-shadow: 0 4px 8px rgba(243, 151, 43, 0.3);
    }
  }

  &.success .btn-status__box {
    background-color: #45db54;
    box-shadow: 0 4px 8px rgba(69, 219, 84, 0.2);
  }

  &.rarely .btn-status__box {
    background-color: #45db54;
    box-shadow: 0 4px 8px rgba(69, 219, 84, 0.2);
  }
  &.rarely.modal .btn-status__box {
    background-color: #000000;
  }

  &.no-visits.in-stock .btn-status__box {
    background-color: #fceded;

    span {
      color: #db4545;
    }
  }

  &.rarely.in-stock .stock,
  &.warning.in-stock .stock {
    color: #db4545;
  }

  &.no-link .btn-status__box {

    .status-label,
    .in-stock {
      color: #db4545 !important;
    }
    .status-label__white {
      color: #ffffff !important;
    }
  }

  &.opened {
    z-index: 15;
  }
}
</style>
