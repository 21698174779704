<template>
  <div :class="['property-list', className]" :style="{'left': offsetLeftSearchBy, 'top': offsetTopSearchBy}">
    <div class="square"></div>
    <div class="property-list__content">
      <nav class="navigation">
        <p
          v-for="(elem, index) in listOfProperty" :key="index"
          :class="{active: activeHeader === index}"
          @click.prevent="changeActiveWindow(index)"
        >{{ elem }}</p>
      </nav>
      <slot v-if="listOfProperty[activeHeader] === 'Search'" name="Search"/>
      <slot v-if="listOfProperty[activeHeader] === 'Status'" name="Status"/>
      <slot v-if="listOfProperty[activeHeader] === 'Mapping'" name="Mapping"/>
      <slot v-if="listOfProperty[activeHeader] === 'Alias'" name="Alias"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "PropertyList",
  props: {
    className: {
      type: String,
      default: () => "",
    },
    offsetTopSearchBy: {
      type: String,
      default: () => "",
    },
    offsetLeftSearchBy: {
      type: String,
      default: () => "",
    },
    listOfProperty: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      activeHeader: 0
    }
  },
  methods: {
    changeActiveWindow(newActive) {
      this.activeHeader = newActive
    }
  }
}
</script>

<style lang="scss" scoped>
.property-list {
  position: absolute;
  transform: translateY(5px) translateX(-22%);
  z-index: 50;

  &__content {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    min-width: 250px;
    padding: 0 16px 16px 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    z-index: 2;
  }

  &.--bottom {
    transform: translateY(-102%) translateX(-22%);

    .square {
      top: auto;
      bottom: -11px;
    }

    &.--left {
      transform: translateY(-102%) translateX(0);

      .square {
        left: 25%;
      }
    }
  }

  &.--left {
    transform: translateY(0) translateX(0);

    .square {
      left: 25%;
    }
  }

  .navigation {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    padding-top: 22px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin-right: 20px;
      text-transform: uppercase;
      cursor: pointer;
      color: #727e85;
    }

    .active {
      border-bottom: 1px solid #000000;
      color: #000000;
    }
  }
}

.square {
  height: 12px;
  width: 12px;
  background-color: #ffffff;
  transform: rotate(45deg) translateY(-20%) translateX(-50%);
  position: absolute;
  top: 0;
  left: 49%;
}
</style>
