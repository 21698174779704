<template>
  <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.61099 10.5C2.02099 10.5 1.56099 10.03 1.56099 9.45C1.56099 8.86 2.03099 8.41 2.61099 8.41C3.20099 8.41 3.65099 8.86 3.65099 9.45C3.65099 10.03 3.21099 10.5 2.61099 10.5ZM5.12099 4.33C4.49099 5.26 3.89099 5.54 3.56099 6.14C3.48099 6.28 3.43099 6.4 3.40099 6.63C3.35099 7.02 3.04099 7.31 2.65099 7.31H2.62099C2.18099 7.31 1.83099 6.93 1.87099 6.49C1.90099 6.22 1.96099 5.92 2.12099 5.65C2.53099 4.92 3.30099 4.49 3.75099 3.85C4.23099 3.17 3.96099 1.91 2.61099 1.91C2.00099 1.91 1.60099 2.23 1.35099 2.61C1.16099 2.9 0.780987 3 0.460987 2.86C0.0409875 2.68 -0.139012 2.16 0.120988 1.79C0.630988 1.05 1.48099 0.5 2.60099 0.5C3.83099 0.5 4.68099 1.06 5.11099 1.76C5.47099 2.37 5.69099 3.49 5.12099 4.33Z" fill="#F3972B" stroke="#F3972B"/>
  </svg>
</template>

<script>
export default {
  name: "Question"
}
</script>
