/**
 * User: denisverstov
 * Date: 05.02.2021
 * Time: 03:01
 */

/**
 *
 * @param {object} element
 * @param {string} className
 * @returns {boolean}
 */

const hasClassOrParent = (element, className) => {
  do {
    if (element.classList && element.classList.contains(className)) {
      return true;
    }
    element = element.parentNode;
  } while (element);
  return false;
};

export {
  hasClassOrParent,
};
