<template>
  <Portal to="body">
    <div class="preview-wrapper">
      <div class="preview-wrapper-sub">
        <div class="toggle-epack-close" @click="$emit('disable')"/>
        <div class="toggle-epack">
          <div class="toggle-epack-header">
            Warning
          </div>
          <div class="toggle-epack-text">
            Are you sure you want
            <span class="toggle-epack-text__important">
              {{
                isActivate ?
                  "disable" :
                  "enable"
              }}
            </span>
            epack's broadcast on all retailers?
          </div>
          <div class="toggle-epack-options">
            <div class="toggle-epack-button" @click="$emit('toggleEpackConfirm', isActivate, epackID)">
              Accept
            </div>
            <div class="toggle-epack-button" @click="$emit('disable')">
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  </Portal>
</template>

<script>
import {Portal} from "portal-vue";

export default {
  name: "ToggleEpack",

  components: {
    Portal
  },

  props: {
    isActivate: Boolean,
    epackID: {
      type: String,
      default: ""
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.68);

  &-sub {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 6px;
    transform: translateX(calc(50vw - 50%)) translateY(calc(50vh - 50%));
    position: absolute;
    opacity: 1;
    max-width: 1100px;
  }
}

.toggle-epack {
  margin: 40px;
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  text-align: start;

  &-header {
    font-style: normal;
    font-weight: 550;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  &-epack {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #727e85;
  }

  &-button {
    display: flex;
    align-self: flex-end;
    align-items: center;
    padding: 12px 30px;
    background: #000000;
    border-radius: 6px;
    margin-top: 32px;
    color: white;
    cursor: pointer;

    &:last-child {
      margin-left: 5px;
    }

    &:hover {
      background-color: #1e1e1e !important;
    }
  }

  &-text {
    margin-top: 10px;
    max-width: 300px;

    &__important {
      font-weight: bold;
    }
  }

  &-options {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-close {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 19px;
    right: 19px;
    cursor: pointer;

    &::before {
      position: absolute;
      content: "";
      top: 12px;
      left: 4px;
      background-color: #727e85;
      width: 18px;
      height: 2px;
      transform: rotate(45deg);
    }

    &::after {
      position: absolute;
      content: "";
      top: 12px;
      left: 4px;
      background-color: #727e85;
      width: 18px;
      height: 2px;
      transform: rotate(-45deg);
    }

    &:hover {
      &::before {
        background-color: black;
      }

      &::after {
        background-color: black;
      }
    }
  }
}
</style>
