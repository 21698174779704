export default {
  data() {
    return {
      alphabetUpper: [
        '%C0',
        '%C1',
        '%C2',
        '%C3',
        '%C4',
        '%C5',
        '%C6',
        '%C7',
        '%C8',
        '%C9',
        '%CA',
        '%CB',
        '%CC',
        '%CD',
        '%CE',
        '%CF',
        '%D0',
        '%D1',
        '%D2',
        '%D3',
        '%D4',
        '%D5',
        '%D6',
        '%D7',
        '%D8',
        '%D9',
        '%DA',
        '%DB',
        '%DC',
        '%DD',
        '%DE',
        '%DF',
        '%A8',
      ],
      alphabet: [
        '%E0',
        '%E1',
        '%E2',
        '%E3',
        '%E4',
        '%E5',
        '%E6',
        '%E7',
        '%E8',
        '%E9',
        '%EA',
        '%EB',
        '%EC',
        '%ED',
        '%EE',
        '%EF',
        '%F0',
        '%F1',
        '%F2',
        '%F3',
        '%F4',
        '%F5',
        '%F6',
        '%F7',
        '%F8',
        '%F9',
        '%FA',
        '%FB',
        '%FC',
        '%FD',
        '%FE',
        '%FF',
        '%B8',
      ],
    }
  },

  methods: {
    encode(text) {
      let finalText = '';
      for (let i in text) {
        const charCode = text.charCodeAt(i)
        let toAdd = ''
        if (charCode > 1000) {
          toAdd = (charCode === 1025 ? this.alphabetUpper[this.alphabetUpper.length - 1] :
            (charCode === 1105 ? this.alphabetUpper[this.alphabetUpper.length - 1] :
              (charCode >= 1072 ? this.alphabet[charCode - 1072] : this.alphabetUpper[charCode - 1040])))
        } else {
          toAdd = text[i]
        }
        finalText = finalText + toAdd
      }
      return finalText
    }
  }
}
