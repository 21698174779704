/**
 * User: denisverstov
 * Date: 04.02.2021
 * Time: 04:13
 */

/**
 *
 * @param {string} link
 */

const downloadFile = link => {
  let linkNode = document.createElement("a");
  linkNode.setAttribute("href", link);
  linkNode.setAttribute("download", "");
  document.body.appendChild(linkNode);
  linkNode.click();
  document.body.removeChild(linkNode);
};


export {
  downloadFile,
};
