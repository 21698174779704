<template>
  <svg @click="openPreview(epackID)" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0-132618)">
      <path d="M20.625 20.25H3.37501C3.16801 20.25 3 20.418 3 20.625C3 20.832 3.16801 21 3.37501 21H20.625C20.832 21 21 20.832 21 20.625C21 20.418 20.832 20.25 20.625 20.25Z" />
      <path d="M6.37501 15.75H4.12501C3.91801 15.75 3.75 15.918 3.75 16.125V20.625C3.75 20.832 3.91801 21 4.12501 21H6.37501C6.58201 21 6.75002 20.832 6.75002 20.625V16.125C6.75002 15.918 6.58201 15.75 6.37501 15.75ZM6 20.25H4.50002V16.5H6.00004V20.25H6Z" />
      <path d="M10.875 12H8.62501C8.41801 12 8.25 12.168 8.25 12.375V20.625C8.25 20.832 8.41801 21 8.62501 21H10.875C11.082 21 11.25 20.832 11.25 20.625V12.375C11.25 12.168 11.082 12 10.875 12ZM10.5 20.25H8.99999V12.75H10.5V20.25Z" />
      <path d="M15.375 13.5H13.125C12.918 13.5 12.75 13.668 12.75 13.875V20.625C12.75 20.832 12.918 21 13.125 21H15.375C15.582 21 15.75 20.832 15.75 20.625V13.875C15.75 13.668 15.582 13.5 15.375 13.5ZM15 20.25H13.5V14.25H15V20.25V20.25Z" />
      <path d="M19.875 9H17.625C17.418 9 17.25 9.16801 17.25 9.37501V20.625C17.25 20.832 17.418 21 17.625 21H19.875C20.082 21 20.25 20.832 20.25 20.625V9.37501C20.25 9.16798 20.082 9 19.875 9ZM19.5 20.25H18V9.74999H19.5V20.25V20.25Z" />
      <path d="M5.25001 9.75C4.42275 9.75 3.75 10.4228 3.75 11.25C3.75 12.0773 4.42275 12.75 5.25001 12.75C6.07727 12.75 6.75002 12.0773 6.75002 11.25C6.75002 10.4228 6.07727 9.75 5.25001 9.75ZM5.25001 12C4.83601 12 4.50002 11.6632 4.50002 11.25C4.50002 10.8368 4.83601 10.5 5.25001 10.5C5.66401 10.5 6 10.8368 6 11.25C6 11.6632 5.66401 12 5.25001 12Z" />
      <path d="M9.75001 6C8.92275 6 8.25 6.67275 8.25 7.50001C8.25 8.32727 8.92275 9.00002 9.75001 9.00002C10.5773 9.00002 11.25 8.32727 11.25 7.50001C11.25 6.67275 10.5773 6 9.75001 6ZM9.75001 8.25C9.33601 8.25 9.00002 7.91324 9.00002 7.50001C9.00002 7.08675 9.33601 6.75002 9.75001 6.75002C10.164 6.75002 10.5 7.08678 10.5 7.50001C10.5 7.91327 10.164 8.25 9.75001 8.25Z" />
      <path d="M14.25 7.5C13.4227 7.5 12.75 8.17275 12.75 9.00001C12.75 9.82727 13.4227 10.5 14.25 10.5C15.0773 10.5 15.75 9.82727 15.75 9.00001C15.75 8.17275 15.0773 7.5 14.25 7.5ZM14.25 9.75C13.836 9.75 13.5 9.41324 13.5 9.00001C13.5 8.58675 13.836 8.25002 14.25 8.25002C14.664 8.25002 15 8.58679 15 9.00001C15 9.41324 14.664 9.75 14.25 9.75Z" />
      <path d="M18.75 3C17.9227 3 17.25 3.67275 17.25 4.50001C17.25 5.32727 17.9227 6.00002 18.75 6.00002C19.5773 6.00002 20.25 5.32727 20.25 4.50001C20.25 3.67275 19.5773 3 18.75 3ZM18.75 5.25C18.336 5.25 18 4.91324 18 4.50001C18 4.08675 18.336 3.75002 18.75 3.75002C19.164 3.75002 19.5 4.08679 19.5 4.50001C19.5 4.91324 19.164 5.25 18.75 5.25Z" />
      <path d="M18.2203 5.02949C18.0741 4.88324 17.8363 4.88324 17.6901 5.02949L14.7801 7.93948C14.6338 8.08573 14.6338 8.32349 14.7801 8.46974C14.8536 8.54248 14.9496 8.57925 15.0456 8.57925C15.1408 8.57925 15.2368 8.54325 15.3104 8.46974L18.2203 5.55975C18.3666 5.4135 18.3666 5.17574 18.2203 5.02949Z" />
      <path d="M13.3434 8.13451L10.9343 7.44752C10.7326 7.38902 10.5278 7.50602 10.4716 7.70553C10.4146 7.90504 10.5301 8.11204 10.7288 8.16903L13.1378 8.85602C13.1723 8.86576 13.2068 8.87026 13.2406 8.87026C13.4041 8.87026 13.5541 8.763 13.6013 8.59801C13.6584 8.39927 13.5429 8.1915 13.3434 8.13451Z" />
      <path d="M9.16486 7.96723C9.03587 7.80523 8.80036 7.77897 8.63836 7.90722L5.81084 10.1617C5.64884 10.2915 5.62184 10.527 5.75161 10.689C5.82586 10.7819 5.93537 10.83 6.04484 10.83C6.12658 10.83 6.20909 10.803 6.27811 10.749L9.10562 8.49447C9.26762 8.36471 9.29462 8.12923 9.16486 7.96723Z" />
    </g>
    <defs>
      <clipPath id="clip0-132618">
        <rect width="18" height="18" fill="white" transform="translate(3 3)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {
    name: "Analytic",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
