+<template>
  <div class="search-by">
    <div class="search-by__content">
      <div class="search-by__wrapper">
        <div v-if="Array.isArray(retailer.humanDate) && retailer.humanDate[1]"
             class="search-by__box">
          <div class="search-by__box__title">Last visited</div>
          <div class="blobs">
            <div>{{ retailer.humanDate[0] }}</div>
            <div>{{ retailer.humanDate[1] }}</div>
            <div class="search-by__delete__last" title="Delete link from last visit" @click="deleteLastVisited"></div>
            </div>
        </div>
        <div v-if="(retailer.status === 'no-visits' || retailer.status === 'no-map') && !retailer.link"
             class="search-by__box">
          <div class="search-by__box__title">Search by</div>
          <div class="blobs">
            <a :href="retailerSearch + (retailer.group && retailer.group.name === 'Onlinetrade' ? encodeURItoCP866(retailer.productName) : retailer.productName)" class="blob"
               rel="noopener noreferrer" target="_blank">Product
              name</a>
            <a :href="retailerSearch + retailer.ean" class="blob"
               rel="noopener noreferrer" target="_blank">EAN</a>
            <a :href="retailerSearch + retailer.mpn"
               class="blob"
               rel="noopener noreferrer"
               target="_blank">MPN</a>
          </div>
          <div class="blobs" style="width: 150%;">
            <template v-if="retailer.link">
              <a
                v-if="String(retailer.link).includes('?')"
                :href="retailer.link" :style="{'margin-top': 0}" class="blob"
                rel="noopener noreferrer"
                target="_blank">Link</a>
              <a v-else-if="String(retailer.link).includes('/#/search')"
                 :href="String(retailer.link).slice(0, String(retailer.link).indexOf('/#/search')) +
                 String(retailer.link).slice(String(retailer.link).indexOf('/#/search'))"
                 :style="{'margin-top': 0}"
                 class="blob"
                 rel="noopener noreferrer"
                 target="_blank">Link</a>
              <a v-else
                 :href="retailer.link"
                 :style="{'margin-top': 0}"
                 class="blob"
                 rel="noopener noreferrer"
                 target="_blank">Link</a>
              <button class="blob blob--edit" :style="{'margin-top': 0}" @click="$emit('open-link-modal', retailer)">
                <Edit />
                <span>Edit link</span>
              </button>
            </template>
            <template v-else>
              <button class="blob blob--plus" @click="$emit('open-link-modal', retailer)">
                <Plus />
                <span>Add link</span>
              </button>
            </template>
          </div>
        </div>
      </div>
      <div v-if="!((retailer.status === 'no-visits' || retailer.status === 'no-map') && !retailer.link)"
        class="search-by__box">
        <div class="blobs">
          <template v-if="retailer.link">
            <a
              v-if="String(retailer.link).includes('?')"
              :href="retailer.link" :style="{'margin-top': 0}" class="blob"
              rel="noopener noreferrer"
              target="_blank">Link</a>
            <a v-else-if="String(retailer.link).includes('/#/search')"
              :href="String(retailer.link).slice(0, String(retailer.link).indexOf('/#/search')) +
                 String(retailer.link).slice(String(retailer.link).indexOf('/#/search'))"
              :style="{'margin-top': 0}"
              class="blob"
              rel="noopener noreferrer"
              target="_blank">Link</a>
            <a v-else
              :href="retailer.link"
              :style="{'margin-top': 0}"
              class="blob"
              rel="noopener noreferrer"
              target="_blank">Link</a>
            <button class="blob blob--edit" :style="{'margin-top': 0}" @click="$emit('open-link-modal', retailer)">
              <Edit />
              <span>Edit link</span>
            </button>
          </template>
          <template v-else>
            <button class="blob blob--plus" @click="$emit('open-link-modal', retailer)">
              <Plus />
              <span>Add link</span>
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/API/API";
import {showToastError, showToastSuccess} from "@/services/Helpers/HelperToast";
import urlEncoder from "@/mixins/urlEncoder";
import Plus from "../ui/icons/Plus";
import Edit from "../ui/icons/Edit";

export default {
  name: "SearchBy",
  mixins: [urlEncoder],
  components: {
    Plus,
    Edit,
  },
  props: {
    retailerSearch: {
      type: String,
      default: () => "",
    },
    retailer: {
      type: Object,
      default: () => {
      },
    }
  },

  data() {
    return {
      inStock: this.retailer.stock,
      visits: this.retailer.status,
      checked: this.retailer.check,
      reason: this.retailer.reason || 1,
      reasonLink: this.retailer.reasonLink || ''
    };
  },

  created() {
    if (this.retailer.link !== undefined)
      this.retailer.link = this.retailer.link.replaceAll('?24StreamContentChecking=true', '').replaceAll('&24StreamContentChecking=true', '')
  },

  watch: {
    retailer: {
      handler: function (val) {
        this.retailer = val;
        this.inStock = val.stock;
        this.visits = val.status;
        this.checked = val.check;
        this.reason = val.reason || 1;
        this.reasonLink = val.reasonLink || '';
      },
      deep: true,
    },
  },

  methods: {
    deleteLastVisited() {
      API.APIPut(`${process.env.VUE_APP_API_URL}/epackage/${this.retailer.epackID}/${this.retailer.id}/clear-last-visited`, {}, response => {
          if (response === true) {
            showToastSuccess(
              "Last visit deleted",
              this.$toast
            )
            this.retailer.humanDate = "None"
            this.retailer.link = ""
            this.retailer.status = "no-visits"
            this.retailer.noLink = false
            this.retailer.text = "No Visits"
          } else if (response.code !== 200) {
            showToastError(
              response.message,
              this.$toast
            );
          }
        },
        () => {
          showToastError(
            "Something wrong. <br/> Please try later",
            this.$toast
          );
          this.loading = false;
        })
    },

    encodeURItoCP866(text) {
      return this.encode(text)
    }
  }
};
</script>

<style lang="scss" scoped>
.search-by {

  &__wrapper {
    width: 154px;
    display: flex;
    flex-direction: column;
  }

  &__box {
    margin-top: 16px;

    .checkboxes {
      .button__group {
        margin: 5px;
        border: 1px solid #e3e9ed;
        width: 87px;
        height: 32px;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &__elem {
          padding: 5px !important;
          border: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          width: 38px;
          height: 24px;
          text-align: center;
        }

        &__black {
          color: #ffffff !important;
          background-color: #000000 !important;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__white {
          color: #000000 !important;
          background-color: #ffffff !important;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &__title {
      text-align: left;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;

      &__sub {
        color: #8d9ca8 !important;
      }
    }

    .blobs {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;

      .blob {
        padding: 8px 16px;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
        margin-top: 8px;
        border-radius: 6px;
        background-color: #000000;
        cursor: pointer;
        font-weight: 600;
        height: fit-content;

        &:first-child {
          margin-top: 8px;
        }

        &:not(:last-child) {
          margin-right: 8px;
        }

        &--wide {
          width: 100%;
          text-align: center;
        }

        &--plus {
          background: #ffffff;
          border: 1px solid #d6dee2;
          display: flex;
          align-items: center;
          padding: 10px 20px;
          font-weight: 500;
          color: #000000;

          svg {
            margin-right: 8px;
          }
        }

        &--edit {
          display: flex;
          align-items: center;
          background: 0 0;
          border: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #8d9ca8;
          padding: 11px 20px;

          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .va-radio-button {
    margin-top: 5px;

    &.not-active {
      color: #727e85;

      .va-radio-button__icon {
        border-color: #727e85 !important;
      }
    }
  }

  &__delete__last {
    position: absolute;
    width: 24px;
    height: 24px;
    right: -35px;
    cursor: pointer;

    &::before {
      position: absolute;
      content: "";
      top: 12px;
      left: 4px;
      background-color: #727e85;
      width: 14px;
      height: 2px;
      transform: rotate(45deg);
    }

    &::after {
      position: absolute;
      content: "";
      top: 12px;
      left: 4px;
      background-color: #727e85;
      width: 14px;
      height: 2px;
      transform: rotate(-45deg);
    }

    &:hover {
      &::before {
        background-color: black;
      }

      &::after {
        background-color: black;
      }
    }
  }
}
</style>
