<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="the-edit-svg">
    <path d="M8.15659 2.68701H2.59035C2.16857 2.68701 1.76405 2.85457 1.4658 3.15282C1.16755 3.45106 1 3.85558 1 4.27736V15.4098C1 15.8316 1.16755 16.2361 1.4658 16.5344C1.76405 16.8326 2.16857 17.0002 2.59035 17.0002H13.7228C14.1446 17.0002 14.5491 16.8326 14.8474 16.5344C15.1456 16.2361 15.3132 15.8316 15.3132 15.4098V9.8436" stroke="#8D9CA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.1208 1.49406C14.4372 1.17772 14.8662 1 15.3136 1C15.761 1 16.19 1.17772 16.5064 1.49406C16.8227 1.8104 17.0004 2.23945 17.0004 2.68682C17.0004 3.1342 16.8227 3.56325 16.5064 3.87959L8.95219 11.4338L5.77148 12.2289L6.56666 9.04824L14.1208 1.49406Z" stroke="#8D9CA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "Edit",
};
</script>

<style lang="scss">
.the-edit-svg {
  fill: white !important;
}
</style>
