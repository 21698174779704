<template>
  <div class="status">
    <div class="status__content">
      <div class="status__wrapper">
        <div v-if="(retailer.status === 'no-visits' ||
        retailer.status === 'rarely' || retailer.status === 'warning' || retailer.status === 'success' || retailer.noLink) && retailer.stock === 'in-stock'||
         retailer.stock === 'data-exist'"
             class="status__wrapper ml-4">
          <div>
            <h2 class="mt-3">Reason</h2>
            <div class="status__box">
              <div class="checkboxes">
                <va-radio-button
                  :option="2"
                  :value="reason"
                  label="No script"
                  @input="reason = 2"/>
                <va-radio-button
                  :option="4"
                  :value="reason"
                  label="No brand"
                  @input="reason = 4"/>
                <va-radio-button
                  :option="7"
                  :value="reason"
                  label="No ean"
                  @input="reason = 7"/>
                <va-radio-button
                  :option="1"
                  :value="reason"
                  label="Incorrect ean"
                  @input="reason = 1"/>
                <va-radio-button
                  :option="3"
                  :value="reason"
                  label="Incorrect script"
                  @input="reason = 3"/>
                <va-radio-button
                  :option="5"
                  :value="reason"
                  label="Incorrect brand"
                  @input="reason = 5"/>
                <va-radio-button
                  :option="6"
                  :value="reason"
                  label="Incorrect domain"
                  @input="reason = 6"/>

              </div>
            </div>
            <va-input
              v-model="reasonLink"
              :placeholder="$t('Reason link')"
              class="input--border"
            >
            </va-input>
          </div>
          <va-button @click="onReasonSend">SEND</va-button>
        </div>
        <div v-if="retailer.status !== 'no-map'" class="status__box">
          <div class="status__box__title__sub">Checked</div>
          <div class="checkboxes">
            <va-button-group class="button__group">
              <va-button :class="{
                button__group__black: checked,
                button__group__white: !checked
              }"
                         class="button__group__elem"
                         @click="updateCheck(true)">YES
              </va-button>
              <va-button :class="{
                button__group__black: !checked,
                button__group__white: checked
              }"
                         class="button__group__elem"
                         @click="updateCheck(false)">NO
              </va-button>
            </va-button-group>
          </div>
        </div>
        <div class="status__box">
          <div class="status__box__title__sub">Availability in stock</div>
          <div class="checkboxes">
            <va-button-group class="button__group">
              <va-button :class="{
                button__group__black: inStock,
                button__group__white: !inStock
              }"
                         class="button__group__elem"
                         @click="updateStock(true)">YES
              </va-button>
              <va-button :class="{
                button__group__black: !inStock,
                button__group__white: inStock
              }"
                         class="button__group__elem"
                         @click="updateStock(false)">NO
              </va-button>
            </va-button-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VaRadioButton from "vuestic-ui/src/components/vuestic-components/va-radio-button/VaRadioButton";
import API from "@/services/API/API";
import {showToastError, showToastSuccess} from "@/services/Helpers/HelperToast";

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export default {
  name: "Status",
  components: {VaRadioButton},
  props: {
    retailerSearch: {
      type: String,
      default: () => "",
    },
    retailer: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      inStock: this.retailer.stock === "in-stock",
      visits: this.retailer.status,
      checked: this.retailer.check,
      reason: this.retailer.reason || 1,
      reasonLink: this.retailer.reasonLink || '',
    };
  },

  watch: {
    retailer: {
      handler: function (val) {
        this.retailer = val;
        this.inStock = val.stock === "in-stock";
        this.visits = val.status;
        this.checked = val.check;
        this.reason = val.reason || 1;
        this.reasonLink = val.reasonLink || '';
      },
      deep: true,
    },
  },

  methods: {
    updateStock(value) {
      this.inStock = value;
      this.$emit("update", {inStock: value, epackID: this.retailer.epackID, retailer: this.retailer.name});
    },

    updateCheck(value) {
      this.checked = value
      this.$emit("changeCheck", {checked: value, epackID: this.retailer.epackID, retailer: this.retailer.name});
    },

    onReasonSend() {
      if (validURL(this.reasonLink)) {
        API.APIPut(`${process.env.VUE_APP_API_URL}/epackage/${this.retailer.epackID}/${this.retailer.id}/reason`, {
          reason: this.reason,
          reasonLink: this.reasonLink,
        }, () => {
          this.$emit('changeStatusLink', {reasonLink: this.reasonLink, reason: this.reason, retailer: this.retailer})
          showToastSuccess('The reason was sent', this.$toast);
        }, () => {
          showToastError('There was an error while sending the link', this.$toast);
        })
      } else {
        showToastError('The reason link must be a valid url', this.$toast);
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.status {

  &__wrapper {
    width: 154px;
    display: flex;
    flex-direction: column;
  }

  &__box {
    margin-top: 16px;

    .checkboxes {
      .button__group {
        margin: 5px;
        border: 1px solid #e3e9ed;
        width: 87px;
        height: 32px;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &__elem {
          padding: 5px !important;
          border: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          width: 38px;
          height: 24px;
          text-align: center;
        }

        &__black {
          color: #ffffff !important;
          background-color: #000000 !important;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__white {
          color: #000000 !important;
          background-color: #ffffff !important;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &__title {
      text-align: left;
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;

      &__sub {
        color: #8d9ca8 !important;
      }
    }

    .blobs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .blob {
        padding: 8px 16px;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
        margin-top: 8px;
        border-radius: 6px;
        background-color: #000000;
        cursor: pointer;
        font-weight: 600;

        &:first-child {
          margin-top: 8px;
        }

        &--wide {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .va-radio-button {
    margin-top: 5px;

    &.not-active {
      color: #727e85;

      .va-radio-button__icon {
        border-color: #727e85 !important;
      }
    }
  }
}
</style>
