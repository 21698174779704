<template>
  <div class="mapping">
    <div class="mapping__content">
      <div class="mapping__wrapper">
        <VnSelect
          v-model="selectedAlias"
          :disabled="false"
          :label="$t('Alias')"
          :multi-cleanable="true"
          :options="aliasOptions"
          :readonly="readonly"
          class="mapping__elem"
          icon=""
          maxHeight="300px"
          textBy="name"
          :no-clear="true"
          width="200px"
        />
        <div style="position: relative;" class="btns flex md6 xs12 xl12 n-pd">
          <va-button :style="{marginTop: 0, marginBottom: 0}" @click="updateAlias">Save</va-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/API/API";
import {showToastError, showToastSuccess} from "@/services/Helpers/HelperToast";

export default {
  name: "Alias",
  components: {
    // VnInput: () => import('../ui/vn-input'),
    VnSelect: () => import('../ui/vn-select'),
    // IconRefresh: () => import('../ui/icons/IconRefresh')
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    aliases: {
      type: Array,
      default: () => [],
    },
    aliasOptions: {
      type: Array,
      default: () => [],
    },
    retailer: {
      type: Object,
      default: () => {
      },
    },
    retailerName: {
      type: String,
      default: ""
    },
  },
  async created() {

    API.APIGet(`${process.env.VUE_APP_API_URL}/epackage/${this.retailer.epackID}/${this.retailer.id}`, {}, (data) => {
      this.selectedAlias = data.brandAlias?.name
    })
  },
  data() {
    return {
      selectedAlias: null
    }
  },

  methods: {
    updateAlias() {
      let aliasId = this.aliases.find(a => a.name === this.selectedAlias).id
      API.APIPut(`${process.env.VUE_APP_API_URL}/epackage/${this.retailer.epackID}/${this.retailer.id}/update-brand-alias`, {
          brandAliasId: aliasId
        },
        () => {
          showToastSuccess('Alias updated', this.$toast)
        },
        error => showToastError(error?.response?.data?.code === 404 ? "Not found" : error?.response?.data?.error?.length ? error?.response?.data?.error : "Something wrong,<br/>please try later", this.$toast)
      );
    },
  }
}
</script>

<style lang="scss" scoped>
.mapping {
  padding-top: 15px;

  &__update__reason {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 80px;
    top: 20px;
    cursor: pointer;

    &:hover {
      svg {
        fill: black;
      }
    }
  }

  &__wrapper {
    width: 200px;
    display: flex;
    flex-direction: column;
  }

  &__elem {
    margin-bottom: 11px;
  }
}

.n-pd {
  padding-left: 0 !important;
}
</style>
