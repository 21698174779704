<template>
  <va-checkbox class="vn-checkbox" :value="activated" :disabled="disabled" @input="toggle"/>
</template>

<script>
export default {
  name: "vn-checkbox",
  props: {
    activated: {
      type: Boolean,
      default: false
    },
    epackID: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    toggle(value) {
      this.$emit('toggleEpack', !value, this.epackID);
    }
  }
}
</script>

<style>
.vn-checkbox {
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
}
.va-checkbox--selected.va-checkbox .va-checkbox__input {
  background-color: #45db54 !important;
}
</style>
